import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const Kinderklasse = ({ data }) => (
    <InnerPage
        title="Kinderklasse"
        description="Geistige, moralische Eigenschaften mit Kindern entdecken"
    >
        <IntroHolder
            title="Kinderklasse"
            subtitle="Geistige, moralische Eigenschaften mit Kindern entdecken"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Gemeinsames Handeln',
                parent: 'Familie und Kinder',
                'current-item': 'Kinderklasse',
            }}
        >
            <IntroQuote>
                Der Mensch ist ein Bergwerk reich an Edelsteinen von
                unschätzbarem Wert. Nur die Erziehung kann bewirken, dass es
                seine Schätze enthüllt.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Kinder sind der kostbarste Schatz, den eine Gesellschaft
                    haben kann, denn sie tragen die Samen unserer zukünftigen
                    Gesellschaft in sich. Sie werden vor allem dadurch geprägt,
                    wie wir für sie sorgen und sie fördern. Daher ist es
                    wichtig, dass Kinder von Beginn an ihre innewohnenden
                    geistigen Eigenschaften entdecken, wie Liebe, Freundschaft,
                    Gerechtigkeit, Großzügigkeit oder Vertrauen. Mit der Hilfe
                    der Gesellschaft und der Eltern lernen sie, diese Tugenden
                    in ihrem Alltag anzuwenden und in ihrem Umfeld
                    weiterzuentwickeln. Sie prägen die Charakterbildung eines
                    jeden Kindes.
                </p>
                <div>
                    <p>
                        Die Bahá’í bieten weltweit seit vielen Jahren
                        Kinderklassen an, die allen Kindern offen stehen.
                        Entsprechend ihrer Altersgruppe lernen Kinder zwischen
                        fünf und elf Jahren anhand von Geschichten, Liedern,
                        Malvorlagen und Zitaten aus den Bahá’í-Schriften die
                        verschiedenen{' '}
                        <Link to="/woran-bahai-glauben/natur-des-menschen/">
                            Tugenden
                        </Link>{' '}
                        kennen. Mit Hilfe der Eltern können diese bewusst in den
                        Alltag eingebaut und von den Kindern praktisch umgesetzt
                        werden.
                    </p>
                    <p>
                        Auch Spiele und kreative Aktionen sind Teil der
                        Kinderklassen.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Für die Ausbildung zum Kinderklassenlehrer bietet das
                        Trainingsinstitut der Bahá’í-Gemeinde spezielle Kurse
                        an. Die Teilnehmer lernen und werden dabei unterstützt,
                        Kinderklassen aufzubauen und durchzuführen. Durch das
                        Material erwerben sie nicht nur das nötige Werkzeug um
                        Kinderklassen zu leiten, sondern entwickeln auch eine
                        Liebe zu den Fähigkeiten der Kinder in ihrer Vielfalt.
                        Gerade auch junge Menschen widmen sich gerne diesem
                        Dienst und erfahren dadurch eine besondere Verantwortung
                        gegenüber der jüngeren Generation.
                    </p>
                </div>
                <Reference>
                    <p>1. Bahá’u’lláh, Botschaften aus 'Akká 11:3</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default Kinderklasse;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "kinderklasse-feature.jpg" }) {
            ...fluidImage
        }
        collage: file(relativePath: { eq: "Kinderklassen.m.png" }) {
            ...fluidImage
        }
    }
`;
